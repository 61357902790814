import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import FirstImage from "../images/partners/01.png";
import SecondImage from "../images/partners/02.png";
import ThirdImage from "../images/partners/03.png";
import FourthImage from "../images/partners/04.png";
import FifthImage from "../images/partners/05.png";
import SixthImage from "../images/partners/06.png";
import SeventhImage from "../images/partners/07.png";
import PrevArrow from "../images/svg/prevArrow.svg";
import NextArrow from "../images/svg/nextArrow.svg";

const imageStyle = { width: "95px", objectFit: "contain", maxHeight: "102px" };

const MobileSlider = () => {
  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <h2
        className="text-h2mobile sm:text-h2desktop"
        style={{ fontFamily: "Bold", color: "#140E55" }}
      >
        {t("sponsorh1")}
      </h2>
      <p
        className="w-full md:w-3/5 lg:w-5/12"
        style={{
          marginTop: "25px",
          marginBottom: "0px",
          color: "#140E55",
          fontSize: "16px",
        }}
      >
        {t("sponsortext")}
      </p>
      <Slider className="mt-10 h-18" {...settings} autoplay={true}>
        <img className="h-18 object-contain" src={FirstImage} alt="BAM LOGO" />
        <img
          className="h-18 object-contain"
          src={SecondImage}
          alt="Bundesministerium für Wirtschaft und Energie"
        />
        <img className="h-18 object-contain" src={ThirdImage} alt="Exist" />
        <img className="h-18 object-contain" src={FourthImage} alt="ESF" />
        <img className="h-18 object-contain" src={FifthImage} alt="EU" />
        <img
          className="h-18 object-contain"
          src={SixthImage}
          alt="zusammen Zukunft gestalten"
        />
        <img
          className="h-18 object-contain"
          src={SeventhImage}
          alt="Profund Innovation - Freie Universität Berlin"
        />
      </Slider>
    </div>
  );
};

const Partner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="block lg:hidden">
        <MobileSlider />
      </div>
      <div className="hidden lg:block">
        <h2 style={{ fontFamily: "Bold", color: "#140E55" }}>
          {t("sponsorh1")}
        </h2>
        <p
          className="w-full sm:w-3/5 lg:w-5/12"
          style={{
            marginTop: "25px",
            marginBottom: "0px",
            color: "#140E55",
            fontSize: "16px",
          }}
        >
          {t("sponsortext")}
        </p>
        <div className="flex flex-row justify-between items-center mt-8">
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={FirstImage}
            alt="BAM LOGO"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={SecondImage}
            alt="Bundesministerium für Wirtschaft und Energie"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={ThirdImage}
            alt="Exist"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={FourthImage}
            alt="ESF"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={FifthImage}
            alt="EU"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={SixthImage}
            alt="zusammen Zukunft gestalten"
          />
          <img
            className="mt-1 sm:mt-0"
            style={imageStyle}
            src={SeventhImage}
            alt="Profund Innovation - Freie Universität Berlin"
          />
        </div>
      </div>
    </Container>
  );
};

export default Partner;
