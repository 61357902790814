/* eslint-disable import/first */
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import Particles from "react-tsparticles";
import { useTranslation } from "react-i18next";

const throttle = require("lodash/throttle");

import Layout from "../components/layout";
import SEO from "../components/seo";
import TitleSubTitleButton from "../components/TitleSubTitleButton";
import Partner from "../components/Partner";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import ButtonArrow from "../components/ButtonArrow";
import NonBreakingSpaceText from "../components/NonBreakingSpaceText";
import ScrollDownButton from "../components/ScrollDownButton";
import useDeviceDetect from "../components/useDeviceDetect";
import Quote from "../components/Quote";
import ParticleConfig from "../particles/particleconfig";
import ParticleConfigMobile from "../particles/particleconfigMobile";

const IndexPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const particleDiv = useRef(null);
  const [particleHeight, setParticleHeight] = useState(0);

  useEffect(() => {
    const resize = throttle(() => {
      setParticleHeight(particleDiv.current.clientHeight + 72);
    }, 200);

    setParticleHeight(particleDiv.current.clientHeight + 72);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [particleDiv]);

  return (
    <Layout>
      <SEO
        title="Schnelle und einfache Multiplex-Methode für Ihr Labor"
        meta={[
          {
            name: "cache-control",
            content: "public, max-age=0, must-revalidate",
          },
        ]}
      />
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <Particles
          height={particleHeight}
          className="bg-safiaBlue"
          params={isMobile ? ParticleConfigMobile : ParticleConfig}
        />

        <div ref={particleDiv} className="absolute top-0 w-full">
          <div className="container mx-auto px-8">
            <div style={{ paddingTop: "152px", paddingBottom: "80px" }}>
              <h1 className="w-full lg:w-3/4 text-h1mobile sm:text-h1desktop">
                <NonBreakingSpaceText text={t("firstLandingHeadline")} />
              </h1>
              <h2
                className="text-body sm:text-h2desktop w-full sm:w-4/5"
                style={{
                  marginTop: "30px",
                  marginBottom: "40px",
                  fontFamily: "Regular",
                }}
              >
                <NonBreakingSpaceText text={t("firstLandingSubHeadline")} />
              </h2>
              <div>
                <ButtonArrow name={t("product")} linkTo="/produkt/" />
              </div>
              <ScrollDownButton selector="#secondsection" />
            </div>
          </div>
          <div id="secondsection">
            <TitleSubTitleButton
              backgroundColor={"transparent"}
              title={t("firstlandingSecondHeadline")}
            />
          </div>
          <Quote
            backgroundColor={"transparent"}
            quote={t("quote")}
            imageSrc="https://via.placeholder.com/106x106"
          />
        </div>
        <Partner />
        {/* <NewsletterSubscribe /> */}
      </div>
    </Layout>
  );
};

export default IndexPage;
