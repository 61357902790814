import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import QuoteSVG from "../images/svg/quote.svg";
import QuoteIMG from "../images/quote-image.jpg";

const Quote = ({ backgroundColor, quote, imageSrc }) => {
  const { t } = useTranslation();
  return (
    <Container backgroundColor={backgroundColor}>
      <div className="flex flex-wrap">
        <div className="w-full md:w-7/12 md:ml-auto">
          <div className="flex flex-col md:flex-row">
            <div className="mt-0 md:mt-2">
              <QuoteSVG />
            </div>
            <p className="text-h4mobile sm:text-h2desktop mt-3 mb-0 md:mt-0 ml-0 md:ml-5 text-white w-full md:w-9/12 text-left">
              {quote}
            </p>
          </div>
        </div>
        <div className="w-full md:w-4/12 mt-5 md:mt-0">
          <img
            className="mb-5 mx-auto md:mx-0 rounded-full h-28 w-28"
            alt="Prof. Dr. rer. nat. habil. Ulrich Panne"
            src={QuoteIMG}
            height="112"
            width="112"
          />
          <p
            style={{
              fontSize: "16px",
              color: "#fff",
              lineHeight: "25px",
              textAlign: "left",
            }}
          >
            <b>{t("quoteName")}</b>
            <br />
            {t("quoteTitle")}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Quote;
