import {
  ClickMode,
  HoverMode,
  InteractivityDetect,
  MoveDirection,
  ShapeType,
} from "tsparticles";
import {
  InlineArrangement as PolygonMaskInlineArrangement,
  MoveType as PolygonMaskMoveType,
  Type as PolygonMaskType,
} from "tsparticles";
import ParticlePNG from "../images/particles/Particle-60px.png";

const percentValue = 1.275;
const minParticleSize = 7; // mobile / 1.2
const maxParticleSize = minParticleSize * percentValue;
const minimumOpacityParticle = 0.6;
const particleDensity = 30; // 15 for subpages // Mobile 50%

export default {
  particles: {
    collisions: true,
    pauseOnOutsideViewport: true,
    pauseOnBlur: true,
    number: {
      value: particleDensity,
      max: -1,
      density: {
        enable: true,
        area: 1200,
      },
    },
    color: {
      value: "#3C2BFF",
    },
    shape: {
      type: ShapeType.images,
      image: [
        {
          src: ParticlePNG,
          height: maxParticleSize,
          width: maxParticleSize,
        },
      ],
      // polygon: {
      //   sides: 5,
      // },
      // image: {
      //   src: "",
      //   width: 100,
      //   height: 100,
      // },
    },
    stroke: {
      width: 0,
      color: "#000000",
    },
    opacity: {
      value: 1,
      random: { enable: true, minimumValue: minimumOpacityParticle },
      anim: {
        enable: false,
        speed: 1,
        minimumValue: 0.1,
        sync: false,
      },
    },
    size: {
      value: maxParticleSize /* max value */,
      random: { enable: true, minimumValue: minParticleSize /* min value */ },
      anim: {
        enable: false,
        speed: 40,
        minimumValue: 0,
        sync: false,
      },
    },
    links: {
      enable: false,
    },
    move: {
      enable: true,
      speed: 1.2,
      direction: MoveDirection.none,
      random: { enable: true, minimumValue: 1.0 },
      straight: false,
      outMode: "out",
      bounce: true,
      attract: {
        enable: true,
        rotateX: 3000,
        rotateY: 3000,
      },
    },
  },
  interactivity: {
    detectsOn: InteractivityDetect.window,
    events: {
      onHover: {
        enable: true,
        mode: HoverMode.repulse,
      },
      onClick: {
        enable: false,
        mode: ClickMode.push,
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 180,
        links: {
          opacity: 0.35,
        },
      },
      bubble: {
        distance: 200,
        size: 80,
        duration: 0.4,
      },
      repulse: {
        distance: 200,
        duration: 0.1,
        speed: 0.1,
      },
      attract: {
        duration: 0.1,
        //size: 10000,
        speed: 10,
        //value: 1000,
        distance: 500,
      },
      push: {
        quantity: 8,
      },
      remove: {
        quantity: 2,
      },
    },
  },
  detectRetina: true,
  fpsLimit: 30,
  polygon: {
    enable: false,
    scale: 1,
    type: PolygonMaskType.inline,
    inline: {
      arrangement: PolygonMaskInlineArrangement.onePerPoint,
    },
    draw: {
      enable: false,
      stroke: {
        width: 0.5,
        color: "rgba(255, 255, 255, .1)",
      },
    },
    move: {
      radius: 10,
      type: PolygonMaskMoveType.path,
    },
    url: "",
  },
};

// export default {
//   particles: {
//     detectRetina: true,
//     line_linked: {
//       enable: false,
//     },
//     number: {
//       value: 100,
//       density: {
//         enable: false,
//       },
//     },
//     color: {
//       value: "#3C2BFF",
//     },
//     size: {
//       value: 10,
//       random: true,
//       anim: {
//         speed: 1,
//         size_min: 9,
//       },
//     },
//     line_linked: {
//       enable: false,
//     },
//     move: {
//       random: true,
//       speed: 1.2,
//       direction: "none",
//       out_mode: "out",
//       straight: false,
//       outMode: OutMode.bounce,
//       bounce: true,
//       attract: {
//         enable: false,
//         rotateX: 3000,
//         rotateY: 3000,
//       },
//     },
//   },
//   opacity: {
//     value: 1,
//     random: false,
//     anim: {
//       enable: false,
//     },
//   },

//   interactivity: {
//     modes: {
//       repulse: {
//         distance: 100,
//         duration: 5,
//       },
//       push: {
//         quantity: 1000,
//       },
//     },
//     detect_on: "window",
//     events: {
//       onhover: {
// enable: true,
// mode: HoverMode.repulse,
//       },
//     },
//   },
// };

// interactivity: {
//   detectsOn: InteractivityDetect.canvas,
//   events: {
//       onHover: {
//           enable: false,
//           mode: HoverMode.grab
//       },
//       onClick: {
//           enable: false,
//           mode: ClickMode.repulse
//       },
//       resize: true
//   },
//   modes: {
//       grab: {
//           distance: 180,
//           links: {
//               opacity: 0.35
//           }
//       },
//       bubble: {
//           distance: 200,
//           size: 80,
//           duration: 0.4
//       },
//       repulse: {
//           distance: 100,
//           duration: 5
//       },
//       push: {
//           quantity: 4
//       },
//       remove: {
//           quantity: 2
//       }
//   }
// },
