import React from "react";
import { useTranslation } from "react-i18next";
import NonBreakingSpaceText from "./NonBreakingSpaceText";
import Container from "./Container";
import ButtonArrow from "../components/ButtonArrow";

const TitleSubTitleButton = ({ title, subTitle, linkTo, backgroundColor }) => {
  const { t } = useTranslation();
  return (
    <Container backgroundColor={backgroundColor}>
      <h2
        className="text-h4mobile sm:text-h2desktop w-full sm:w-9/12"
        style={{ fontFamily: "Bold" }}
      >
        {title}
      </h2>
      <p
        className="text-white w-full sm:w-9/12"
        style={{ marginTop: "30px", marginBottom: "48px" }}
      >
        <NonBreakingSpaceText text={t("landingPage03h2")} />
        <br />
        <br />
        <NonBreakingSpaceText text={t("landingPage03h2First")} />
      </p>
      <ButtonArrow linkTo="/innovation/" name="Innovation" />
    </Container>
  );
};

const style = {
  paddingTop: "80px",
  paddingBottom: "80px",
};

export default TitleSubTitleButton;
